import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { CMSLinkProps } from '~/components/atoms/Link/Link.types';
import useUIStore from '~/state/ui';
import customBreakpoints from '~/styles/theme/customBreakpoints.module.css';
import { getSpacer } from '~/utils';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import isBreakpointOrGreater from '~/utils/isBreakpointOrGreater';
import { getNumberFromString } from '~/utils/Strings';
import { tickerAddOnce } from '~/utils/ticker';

import useNavState from '../Navigation.state';
import { NavigationNavChildProps, NavigationState } from '../Navigation.types';
import useBanner from './useBanner';
import useMainNavActiveItemDelay from './useMainNavActiveItemDelay';
import { UseNavBarProps } from './useNavBar.types';
import useNavScroll from './useNavScroll';
import useSubNav from './useSubNav';

const UIStore = useUIStore;

const getElBounds = ($el: HTMLDivElement | null) => {
  return $el?.getBoundingClientRect();
};

// get breakpoint from custom breakpoints defined in css
const navLg = getNumberFromString(customBreakpoints.navLg);

const useNavBar = (props: UseNavBarProps) => {
  const {
    navigation,
    refs: { $el, $mainNavEl, $subNavEl, $bannerEl },
  } = props;
  const pathname = usePathname();
  const [
    activeMainNavItem,
    currentOpenedSubNav,
    currentPageMainNavId,
    currentPageSubNavId,
    setCurrentGroup,
    setIsMobileNav,
    setCurrentPageMainNavId,
    setCurrentPageSubNavId,
    setActiveSubNavItem,
  ] = useNavState(
    (state) => [
      state.activeMainNavItem,
      state.currentOpenedSubNav,
      state.currentPageMainNavId,
      state.currentPageSubNavId,
      state.setCurrentGroup,
      state.setIsMobileNav,
      state.setCurrentPageMainNavId,
      state.setCurrentPageSubNavId,
      state.setActiveSubNavItem,
    ],
    shallow,
  );
  const lgBreakpoint = navLg;
  const breakpoint = useUIStore((state) => state.breakpoint);
  const windowWidth = useUIStore((state) => state.windowWidth);
  const windowHeight = useUIStore((state) => state.windowHeight);
  const setNavigationHeight = useUIStore((state) => state.setNavigationHeight);
  const setScrollPaddingTop = useUIStore((state) => state.setScrollPaddingTop);
  const initialVars: NavigationState = {
    breakpoint: UIStore.getState().breakpoint,
    mainNav: {
      triggerStart: 0,
      triggerEnd: 0,
      height: 0,
      distanceToBanner: 0,
    },
    subNav: {
      triggerStart: 0,
      height: 0,
      distanceToMainNav: 0,
    },
    banner: {
      triggerStart: 0,
      triggerEnd: 0,
      height: 0,
    },
    // how many pixels scrolling back up to trigger nav to reappear
    maxScrollUpDiff: 0,
    navOffset: 0,
    hasBlur: true,
  };

  const vars = useRef(initialVars);

  //   Check whether or not we scrolled past the Sub Nav
  function isPastSubNav() {
    const scrollY = ScrollPosition.y || 0;
    return (
      scrollY > vars.current.subNav.triggerStart + vars.current.subNav.height
    );
  }

  // custom hooks that handles differents functionalities of the nav:
  // - hook that handles the main navigation active state
  const {
    setActiveMainNavItemImmediately,
    setActiveMainNavItemToCurrentPageWithDelay,
    setActiveMainNavItemToCurrentPageImmediatly,
    clearMainNavActiveStateDelay,
  } = useMainNavActiveItemDelay();
  // - hook that handles the banner
  const { bannerDismissed, setBannerAnimation, dismissBanner } = useBanner(
    $el,
    $bannerEl,
  );
  // - hook that handles the subnav
  const { showDesktopSubNav, hideDesktopSubNav, clearHideSubNavDelay } =
    useSubNav({
      setActiveMainNavItemImmediately,
      setActiveMainNavItemToCurrentPageWithDelay,
    });
  // - hook that handles the scroll listening
  useNavScroll($el, vars.current, { isPastSubNav });

  // get current page main nav child id
  useEffect(() => {
    let mainNavId = null;
    let subNavId = null;

    const currentPath = pathname?.split('/')[1] || '';

    navigation.primaryLinks?.forEach((item: NavigationNavChildProps) => {
      if (item._type === 'link' && item.url === pathname) {
        mainNavId = item._key;
        return;
      } else if (item._type === 'navGroup') {
        if (item.groupItemLinks.includes(currentPath)) {
          mainNavId = item._key;
          item.group?.forEach((groupItem: CMSLinkProps) => {
            if (groupItem._type === 'link' && groupItem.url === pathname) {
              subNavId = groupItem._key;
              return;
            }
          });
        }
      } else {
        return;
      }
    });
    setCurrentPageMainNavId(mainNavId);
    setCurrentPageSubNavId(subNavId);
  }, [pathname, navigation, setCurrentPageMainNavId, setCurrentPageSubNavId]);

  // On main nav change, make sure subnav is matching current subnavId if there is any
  useEffect(() => {
    setActiveMainNavItemToCurrentPageImmediatly();
    setActiveSubNavItem(currentPageSubNavId);
  }, [
    setActiveMainNavItemToCurrentPageImmediatly,
    currentPageMainNavId,
    setActiveSubNavItem,
    currentPageSubNavId,
  ]);

  const onMouseOverMainNavItem = useCallback(
    (id: string | undefined) => {
      if (breakpoint && !isBreakpointOrGreater(breakpoint, 'md')) return;
      if (id) setActiveMainNavItemImmediately(id);
    },
    [breakpoint, setActiveMainNavItemImmediately],
  );

  const onMouseOutMainNavItem = useCallback(() => {
    if (breakpoint && !isBreakpointOrGreater(breakpoint, 'md')) return;
    setActiveMainNavItemToCurrentPageWithDelay();
  }, [breakpoint, setActiveMainNavItemToCurrentPageWithDelay]);

  //   get sizes and calculate trigger values, on resize
  useEffect(() => {
    // ticker only fires once
    tickerAddOnce(() => {
      vars.current.mainNav.height =
        getElBounds($mainNavEl.current)?.height || 0;
      vars.current.subNav.height = getElBounds($subNavEl.current)?.height || 0;
      vars.current.banner.height = getElBounds($bannerEl.current)?.height || 0;

      setNavigationHeight(vars.current.mainNav.height);
      setScrollPaddingTop(vars.current.mainNav.height + getSpacer(54));

      const wh = windowHeight || 0;

      vars.current.banner.triggerStart = $bannerEl.current ? wh / 4 : 0;
      vars.current.banner.triggerEnd =
        vars.current.banner.triggerStart + vars.current.banner.height;

      vars.current.mainNav.triggerStart = wh;
      vars.current.mainNav.triggerEnd = wh + vars.current.mainNav.height;

      vars.current.mainNav.distanceToBanner =
        vars.current.mainNav.triggerStart - vars.current.banner.triggerEnd;

      vars.current.subNav.triggerStart =
        wh * 2 + vars.current.mainNav.triggerEnd - vars.current.subNav.height;
      vars.current.subNav.distanceToMainNav =
        vars.current.subNav.triggerStart - vars.current.mainNav.triggerEnd;

      vars.current.maxScrollUpDiff = wh / 3;

      if (vars.current.banner.height)
        setBannerAnimation(vars.current.banner.height);
    }, true);
  }, [
    windowWidth,
    windowHeight,
    setNavigationHeight,
    $el,
    $bannerEl,
    $mainNavEl,
    $subNavEl,
    setBannerAnimation,
    setScrollPaddingTop,
  ]);

  useEffect(() => {
    // if banner is hidden, reset vars
    if (bannerDismissed) {
      vars.current.banner.height = 0;
      vars.current.banner.triggerStart = 0;
      vars.current.banner.triggerEnd = 0;

      vars.current.mainNav.distanceToBanner =
        vars.current.mainNav.triggerStart - vars.current.banner.triggerEnd;
    }
  }, [bannerDismissed]);

  // on load and route change figure out if we are currently on a subnav page
  useEffect(() => {
    setActiveMainNavItemToCurrentPageImmediatly();
    setCurrentGroup(null);

    navigation.primaryLinks?.forEach((item: NavigationNavChildProps) => {
      if (item._type === 'navGroup') {
        const currentPath = pathname?.split('/')[1] || '';
        const isCurrentGroup = item.groupItemLinks.includes(currentPath);
        if (isCurrentGroup) {
          if (item._key) {
            setCurrentGroup(item._key);
            return;
          }
        }
      }
    });
  }, [
    navigation,
    pathname,
    setCurrentGroup,
    setActiveMainNavItemToCurrentPageImmediatly,
  ]);

  useEffect(() => {
    // clear timeout of showing subnav when route changes
    clearHideSubNavDelay();
    // clear timeout of resetting link active state highlight
    clearMainNavActiveStateDelay();
  }, [pathname, clearHideSubNavDelay, clearMainNavActiveStateDelay]);

  // Effect to set mobile
  useEffect(() => {
    // TODO: using window.innerWidth until the ui store's window width matches window innerwidth instead of document.body width
    // fixes issue with desktop subnav dropdown not working between 1240 and 1255
    if (lgBreakpoint && window.innerWidth >= lgBreakpoint) {
      setIsMobileNav(false);
    } else {
      setIsMobileNav(true);
    }
  }, [windowWidth, lgBreakpoint, setIsMobileNav]);

  return [
    // state
    { activeMainNavItem, currentOpenedSubNav, hasBlur: vars.current.hasBlur },
    // methods
    {
      dismissBanner,
      onMouseOverMainNavItem,
      onMouseOutMainNavItem,
      showDesktopSubNav,
      hideDesktopSubNav,
      setActiveMainNavItemToCurrentPageWithDelay,
      clearMainNavActiveStateDelay,
    },
    //   https://fettblog.eu/typescript-react-typeing-custom-hooks/
  ] as const;
};

export default useNavBar;
