import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import Link from '~/components/atoms/Link/Link';
import { cn } from '~/utils';

import useNavState from '../Navigation.state';
import styles from './NavItem.module.css';
import { NavItemProps } from './NavItem.types';

const NavItem = ({
  id,
  link,
  icon,
  label,
  isGroupHeader = false,
  className,
  hidden,
  isActive,
  isReducedNav,
  onMouseOver,
  onMouseOut,

  onClick,
}: NavItemProps) => {
  const pathname = usePathname();
  const isCurrentPage = useMemo(
    () => link && pathname === link.url,
    [pathname, link],
  );

  const [isMobileNav] = useNavState((state) => [state.isMobileNav], shallow);

  const innerContent = (
    <span className={styles.innerWrapper}>
      {link?.label ? link.label : label ? label : ''}
      {icon && icon}
    </span>
  );

  const getLinkData = () => {
    if (!isGroupHeader) return link;

    // if item is a group header, it will be a clickable link on desktop and a button that expands the subnav on mobile
    if (isGroupHeader && !isMobileNav) return link;

    return undefined;
  };

  const linkData = getLinkData();

  const classNames = cn(
    styles.navItem,
    isReducedNav && styles.isReducedNavNavItem,
    className,
    isActive && styles.active,
  );
  if (linkData) {
    return (
      // disabling eslint here because we need the anchor to have mouseover and mouseout functionality to trigger the submenu
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        className={classNames}
        aria-current={isCurrentPage ? 'page' : false}
        onMouseOver={() => onMouseOver(id)}
        onMouseOut={onMouseOut}
        onFocus={() => onMouseOver(id)}
        onBlur={onMouseOut}
        tabIndex={hidden ? -1 : 0}
        aria-hidden={hidden}
        onClick={onClick}
        to={linkData}
      >
        {innerContent}
      </Link>
    );
  } else {
    return (
      <button
        className={classNames}
        aria-current={isCurrentPage ? 'page' : false}
        onMouseOver={() => onMouseOver(id)}
        onMouseOut={onMouseOut}
        onFocus={() => onMouseOver(id)}
        onBlur={onMouseOut}
        tabIndex={hidden ? -1 : 0}
        aria-hidden={hidden}
        onClick={onClick}
      >
        {innerContent}
      </button>
    );
  }
};

export default NavItem;
