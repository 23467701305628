import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import useDelayedAction from '~/utils/useDelayedAction';

import useNavState from '../Navigation.state';
import { RESET_ACTIVE_DELAY } from '../Navigation.types';
const useMainNavActiveItem = () => {
  const [currentPageMainNavId, setActiveMainNavItem] = useNavState(
    (state) => [state.currentPageMainNavId, state.setActiveMainNavItem],
    shallow,
  );
  // setup timeout for the active state to come back after hovering out
  const [setMainNavActiveStateDelay, clearMainNavActiveStateDelay] =
    useDelayedAction(RESET_ACTIVE_DELAY);

  // set main active state to current page immediatly
  const setActiveMainNavItemToCurrentPageImmediatly = useCallback(() => {
    clearMainNavActiveStateDelay();
    setActiveMainNavItem(currentPageMainNavId);
  }, [
    clearMainNavActiveStateDelay,
    setActiveMainNavItem,
    currentPageMainNavId,
  ]);

  // set main active state to current page with delay
  const setActiveMainNavItemToCurrentPageWithDelay = useCallback(() => {
    clearMainNavActiveStateDelay();
    setMainNavActiveStateDelay(() => {
      setActiveMainNavItem(currentPageMainNavId);
    });
  }, [
    clearMainNavActiveStateDelay,
    setMainNavActiveStateDelay,
    setActiveMainNavItem,
    currentPageMainNavId,
  ]);

  // set main nav active item immediatly
  const setActiveMainNavItemImmediately = useCallback(
    (id: string) => {
      clearMainNavActiveStateDelay();
      setActiveMainNavItem(id);
    },
    [clearMainNavActiveStateDelay, setActiveMainNavItem],
  );

  // set main nav active item with delay
  const setActiveMainNavItemWithDelay = useCallback(
    (id: string) => {
      clearMainNavActiveStateDelay();
      setMainNavActiveStateDelay(() => {
        setActiveMainNavItem(id);
      });
    },
    [
      clearMainNavActiveStateDelay,
      setMainNavActiveStateDelay,
      setActiveMainNavItem,
    ],
  );

  return {
    setActiveMainNavItemWithDelay,
    setActiveMainNavItemImmediately,
    setActiveMainNavItemToCurrentPageWithDelay,
    setActiveMainNavItemToCurrentPageImmediatly,
    clearMainNavActiveStateDelay,
  };
};

export default useMainNavActiveItem;
