'use client';
import { ForwardedRef, forwardRef } from 'react';

import SvgCloseIcon from '~/assets/svg/close-icon.svg';
import PortableText from '~/components/molecules/PortableText/PortableText';
import { cn } from '~/utils';

import styles from './AnnouncementBanner.module.css';
import { AnnouncementBannerProps } from './AnnouncementBanner.types';

const AnnouncementBanner = (
  { content, onClose, className }: AnnouncementBannerProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <div className={cn(styles.announcementBanner, className)} ref={ref}>
      <PortableText
        value={content}
        className={styles.content}
        // override block styles so we don't get headers and eyebrow styles
        options={{
          block: {
            titles: {
              className: styles.bodies,
            },
            accents: {
              className: styles.bodies,
            },
            bodies: {
              className: styles.bodies,
            },
          },
          marks: {
            link: {
              className: styles.link,
            },
          },
        }}
      />

      <button
        className={styles.closeButton}
        // TODO: Add close button label to dictionary
        aria-label="Close"
        onClick={onClose}
      >
        <SvgCloseIcon className={styles.closeButtonInner} />
      </button>
    </div>
  );
};

export default forwardRef<HTMLDivElement, AnnouncementBannerProps>(
  AnnouncementBanner,
);
