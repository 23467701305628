function getNumberFromString(string: string) {
  const matches = string.match(/\d+/g);
  if (matches) {
    return parseInt(matches[0]);
  } else {
    null;
  }
}

export { getNumberFromString };
