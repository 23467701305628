import { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import useDelayedAction from '~/utils/useDelayedAction';

import useNavState from '../Navigation.state';
import { HIDE_DELAY } from '../Navigation.types';

const useSubNav = ({
  setActiveMainNavItemImmediately,
  setActiveMainNavItemToCurrentPageWithDelay,
}: {
  setActiveMainNavItemImmediately: (id: string) => void;
  setActiveMainNavItemToCurrentPageWithDelay: () => void;
}) => {
  const [setHideSubNavDelay, clearHideSubNavDelay] =
    useDelayedAction(HIDE_DELAY);

  const [currentGroup, setCurrentOpenedSubNav, isMobileNav] = useNavState(
    (state) => [
      state.currentGroup,
      state.setCurrentOpenedSubNav,
      state.isMobileNav,
    ],
    shallow,
  );

  const showDesktopSubNav = useCallback(
    (id: string) => {
      if (isMobileNav) return;
      clearHideSubNavDelay();
      setCurrentOpenedSubNav(id);
      setActiveMainNavItemImmediately(id);
    },
    [
      setCurrentOpenedSubNav,
      isMobileNav,
      clearHideSubNavDelay,
      setActiveMainNavItemImmediately,
    ],
  );

  const hideDesktopSubNav = useCallback(() => {
    if (isMobileNav) return;

    setActiveMainNavItemToCurrentPageWithDelay();
    setHideSubNavDelay(() => {
      setCurrentOpenedSubNav(currentGroup || null);
    });
  }, [
    setCurrentOpenedSubNav,
    currentGroup,
    isMobileNav,
    setHideSubNavDelay,
    setActiveMainNavItemToCurrentPageWithDelay,
  ]);

  // when current group is set, set currentOpenedSubNav
  useEffect(() => {
    setCurrentOpenedSubNav(currentGroup);
  }, [currentGroup, setCurrentOpenedSubNav]);

  return { showDesktopSubNav, hideDesktopSubNav, clearHideSubNavDelay };
};

export default useSubNav;
