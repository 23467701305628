import { useCallback, useRef } from 'react';

const DEFAULT_DELAY = 500;

/**
 * A custom hook to run & clear a delayed callback
 *
 * @param delay - Amount of delay for the delayed callback
 
 * @returns An array containing a setter function for the delayed callback, and function to clear it e.g [setDelayedAction, clearDelay]
 */
const useDelayedAction = (delay: number = DEFAULT_DELAY) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const setDelayedAction = useCallback(
    (callback: () => void) => {
      timeoutRef.current = setTimeout(() => {
        callback();
      }, delay);
    },
    [delay],
  );

  const clearDelay = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  //   https://fettblog.eu/typescript-react-typeing-custom-hooks/
  return [setDelayedAction, clearDelay] as const;
};

export default useDelayedAction;
