'use client';
import { usePathname } from 'next/navigation';
import {
  Children,
  cloneElement,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { shallow } from 'zustand/shallow';

import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import { ForwardedButtonRef } from '~/components/atoms/Buttons/Ctas/Button/Button.types';
import { dict } from '~/data/stores/Dictionary';
import useUIStore from '~/state/ui';
import { cn, killTimeline } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import useFocusTrap from '~/utils/useFocusTrap';

import {
  closeMobileNavAnimation,
  openMobileNavAnimation,
} from '../Navigation.animations';
import mainNavStyles from '../Navigation.module.css';
import useNavState from '../Navigation.state';
import styles from './NavMobile.module.css';
import { NavMobileProps } from './NavMobile.types';
import { ToggleButton } from './ToggleButton';

const NavMobile = ({
  children,
  signupLink,
  freeTrialLink,
  isHidden,
}: NavMobileProps) => {
  const pathname = usePathname();
  const [mobileNavOpen, setMobileNavOpen] = useNavState(
    (state) => [state.mobileNavOpen, state.setMobileNavOpen],
    shallow,
  );

  const [breakpoint, setIsScrollLocked] = useUIStore(
    (state) => [state.breakpoint, state.setIsScrollLocked],
    shallow,
  );

  const $navMobile = useRef<HTMLDivElement>(null);
  const $overlay = useRef<HTMLDivElement>(null);
  const $wrapper = useRef<HTMLDivElement>(null);
  const $mainLinksWrapper = useRef<HTMLUListElement>(null);
  const $mainLinks = useRef<HTMLLIElement[]>([]);
  const $bottomLinksWrapper = useRef<HTMLUListElement>(null);
  const $bottomLink = useRef<ForwardedButtonRef>(null);
  const animation = useRef<GSAPTimeline>();

  const onFocusTrapKeyDown = useFocusTrap($navMobile);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (animation.current?.isActive() || !mobileNavOpen) return;
    if (event.code === 'Escape') {
      setMobileNavOpen(false);
    }

    // Focus trap
    if (onFocusTrapKeyDown) onFocusTrapKeyDown(event);
  };

  // close the mobile nav on route change
  useEffect(() => {
    setMobileNavOpen(false);
  }, [pathname, setMobileNavOpen]);

  useEffect(() => {
    // close nav and clear animation on breakpoint change
    resetAnimation();
    setMobileNavOpen(false);
  }, [breakpoint, setMobileNavOpen]);

  function resetAnimation() {
    if (animation.current) killTimeline(animation.current);
  }

  const toggleNav = useCallback(() => {
    if (animation.current?.isActive()) return;
    setMobileNavOpen(!mobileNavOpen);
  }, [mobileNavOpen, setMobileNavOpen]);

  useEffect(() => {
    if (mobileNavOpen) {
      setIsScrollLocked(true);
      $wrapper.current?.focus();
      animation.current = openMobileNavAnimation({
        breakpoint,
        $overlay,
        $wrapper,
        $mainLinksWrapper,
        $mainLinks,
        $bottomLinksWrapper,
        $bottomLinkArrow: $bottomLink.current?.$icon,
      });
    } else {
      animation.current = closeMobileNavAnimation({
        $overlay,
        $wrapper,
        $mainLinks,
      });
      setIsScrollLocked(false);
    }

    animation.current?.play();
  }, [mobileNavOpen, breakpoint, setIsScrollLocked]);

  const childrenWithRefs = () => {
    return Children.map(children, (child, index) => {
      return cloneElement(child as ReactElement, {
        ref: (element: HTMLLIElement) =>
          addToRefArray({ element, refArray: $mainLinks, index }),
      });
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className={cn(styles.container, isHidden && styles.isHidden)}
      ref={$navMobile}
      role="dialog"
      aria-labelledby="mobileNav"
      onKeyDown={handleKeyDown}
    >
      <h2 id="mobileNav" className="visuallyHidden">
        {dict('mobileNavigation')}
      </h2>
      <div className={styles.navMobileRight}>
        <Button
          to={freeTrialLink}
          buttonVariant="pill"
          buttonColorScheme="solidLoud"
          className={styles.freeTrialLink}
          ref={$bottomLink}
        >
          {freeTrialLink?.label}
        </Button>
        <ToggleButton onClick={toggleNav} />
      </div>
      <div className={cn(styles.navMobile, mobileNavOpen && styles.active)}>
        <div className={styles.navMobileOverlay} ref={$overlay}></div>
        <div
          className={cn(
            styles.navMobileWrapper,
            mobileNavOpen && styles.active,
          )}
          ref={$wrapper}
        >
          <div className={styles.navMobileContent}>
            <div className={styles.navMobileItems}>
              <ul className={styles.navMobileMainLinks} ref={$mainLinksWrapper}>
                {childrenWithRefs()}
              </ul>

              <ul
                className={styles.navMobileBottomLinks}
                ref={$bottomLinksWrapper}
              >
                {freeTrialLink && (
                  <li className={styles.freeTrialLinkWrapper}>
                    <Button
                      to={freeTrialLink}
                      buttonVariant="pill"
                      buttonColorScheme="solidLoud"
                      className={cn(
                        styles.navMobileBottomLink,
                        styles.freeTrialLink,
                      )}
                      ref={$bottomLink}
                    >
                      {freeTrialLink?.label}
                    </Button>
                  </li>
                )}
                {signupLink && (
                  <li>
                    <Button
                      to={signupLink}
                      buttonVariant="textWithIcon"
                      className={cn(styles.signupLink)}
                      ref={$bottomLink}
                    >
                      {signupLink?.label}
                    </Button>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <figure className={cn(mainNavStyles.backdrop, styles.backdrop)} />
        </div>
      </div>
    </div>
  );
};

export default NavMobile;
