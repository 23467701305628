import { gsap } from 'gsap';
import { RefObject } from 'react';

import { Breakpoint } from '~/types';
import { EaseType } from '~/utils/singletons/Easing';

const OPEN_DURATION = 0.6;
const NAV_ITEMS_DURATION_BASE = 0.55;

export const openMobileNavAnimation = ({
  breakpoint,
  $overlay,
  $wrapper,
  $mainLinksWrapper,
  $mainLinks,
  $bottomLinksWrapper,
  $bottomLinkArrow,
}: {
  breakpoint: Breakpoint | null;
  $overlay: RefObject<HTMLDivElement>;
  $wrapper: RefObject<HTMLDivElement>;
  $mainLinksWrapper: RefObject<HTMLUListElement>;
  $mainLinks: RefObject<HTMLLIElement[]>;
  $bottomLinksWrapper: RefObject<HTMLUListElement>;
  $bottomLinkArrow: RefObject<HTMLElement> | undefined;
}) => {
  const totalMainLinks = $mainLinks.current?.length || 1;
  const tl = gsap.timeline({
    paused: true,
  });

  tl.addLabel('start');

  tl.to($overlay.current, {
    duration: OPEN_DURATION,
    scaleX: breakpoint?.name === 'sm' ? 0 : 0.5,
    opacity: 1,
    ease: EaseType.DEFAULT,
  });
  tl.fromTo(
    $wrapper.current,
    {
      x: '100%',
      visibility: 'visible',
    },
    {
      x: '0%',
      duration: OPEN_DURATION,
      ease: EaseType.DEFAULT,
    },
    `start`,
  );
  tl.fromTo(
    $mainLinksWrapper.current,
    {
      x: '-100%',
    },
    {
      x: '0%',
      duration: OPEN_DURATION,
      ease: EaseType.EMBELLISHMENT,
    },
    `start`,
  );

  tl.fromTo(
    $mainLinksWrapper.current,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      delay: 0.1,
      duration: OPEN_DURATION,
      ease: EaseType.EMBELLISHMENT,
    },
    `start`,
  );

  tl.fromTo(
    $mainLinks.current,
    {
      x: '50%',
      opacity: 0,
    },
    {
      x: '0%',
      opacity: 1,
      duration: function (i) {
        return NAV_ITEMS_DURATION_BASE + 0.05 * i;
      },
      ease: EaseType.BASIC_BUTTER,
    },
    `start`,
  );

  tl.fromTo(
    $bottomLinksWrapper.current,
    {
      x: '50%',
      opacity: 0,
    },
    {
      x: '0%',
      opacity: 1,
      duration: NAV_ITEMS_DURATION_BASE + 0.05 * (totalMainLinks - 1),

      ease: EaseType.BASIC_BUTTER,
    },
    `start`,
  );

  if (typeof $bottomLinkArrow !== 'undefined') {
    tl.fromTo(
      $bottomLinkArrow.current,
      {
        x: '-20px',
        opacity: 0,
      },
      {
        x: '0%',
        opacity: 1,
        duration: NAV_ITEMS_DURATION_BASE + 0.05 * totalMainLinks,
        ease: EaseType.BASIC_BUTTER,
      },
      `start`,
    );
  }

  return tl;
};

export const closeMobileNavAnimation = ({
  $overlay,
  $wrapper,
  $mainLinks,
}: {
  $overlay: RefObject<HTMLDivElement>;
  $wrapper: RefObject<HTMLDivElement>;
  $mainLinks: RefObject<HTMLLIElement[]>;
}) => {
  const tl = gsap.timeline({ paused: true });
  tl.addLabel('start');

  tl.to($overlay.current, {
    duration: OPEN_DURATION,
    scaleX: 1,
    opacity: 0,
    ease: EaseType.DEFAULT,
  });

  tl.to(
    $wrapper.current,
    {
      x: '100%',
      duration: OPEN_DURATION,
      ease: EaseType.DEFAULT,
      onComplete: () => {
        gsap.set($wrapper.current, { visibility: 'hidden' });
      },
    },
    `start`,
  );

  tl.to(
    $mainLinks.current,
    {
      opacity: 0,
      duration: OPEN_DURATION,
      ease: EaseType.EMBELLISHMENT,
    },
    `start`,
  );

  return tl;
};

export const openMobileNavSubNavAnimation = ({
  $subNavWrapper,
  $subNavLinks,
}: {
  $subNavWrapper: RefObject<HTMLUListElement>;
  $subNavLinks: RefObject<HTMLLIElement[]>;
}) => {
  const tl = gsap.timeline({ paused: true });

  tl.addLabel('start');

  tl.to($subNavWrapper.current, {
    height: 'auto',
    pointerEvents: 'all',
    duration: NAV_ITEMS_DURATION_BASE,
    ease: EaseType.EMBELLISHMENT,
  });

  tl.fromTo(
    $subNavLinks.current,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: function (i) {
        return NAV_ITEMS_DURATION_BASE + 0.1 * i;
      },
      ease: EaseType.BASIC_BUTTER,
    },
    `start`,
  );

  return tl;
};

export const closeMobileNavSubNavAnimation = ({
  $subNavWrapper,
  $subNavLinks,
}: {
  $subNavWrapper: RefObject<HTMLUListElement>;
  $subNavLinks: RefObject<HTMLLIElement[]>;
}) => {
  const tl = gsap.timeline({
    paused: true,
  });

  tl.to($subNavLinks.current, {
    opacity: 0,
    duration: OPEN_DURATION,
    ease: EaseType.BASIC_BUTTER,
  });

  tl.to(
    $subNavWrapper.current,
    {
      height: 0,
      pointerEvents: 'none',
      duration: OPEN_DURATION,
      ease: EaseType.EMBELLISHMENT,
    },
    `-=${OPEN_DURATION / 2}`,
  );

  return tl;
};

export const dismissBannerAnimation = ({
  $el,
  $bannerEl,
  bannerHeight,
  onComplete,
}: {
  $el: RefObject<HTMLDivElement>;
  $bannerEl: RefObject<HTMLDivElement>;
  bannerHeight: number;
  onComplete: () => void;
}) => {
  const tl = gsap.timeline({
    paused: true,
    onComplete: onComplete,
  });

  tl.to($el.current, {
    y: `-${bannerHeight}px`,
    duration: 0.5,
    ease: EaseType.BASIC_BUTTER,
  });

  tl.to(
    $bannerEl.current,
    {
      opacity: 0.25,
      duration: 0.25,
      ease: EaseType.BASIC_BUTTER,
    },
    `-=.35`,
  );

  return tl;
};
