'use client';
import { usePathname } from 'next/navigation';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { shallow } from 'zustand/shallow';

import SvgCaret from '~/assets/svg/caret.svg';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';
import useUIStore from '~/state/ui';
import { cn, killTimeline } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import isBreakpointOrGreater from '~/utils/isBreakpointOrGreater';
import useDelayedAction from '~/utils/useDelayedAction';

import {
  closeMobileNavSubNavAnimation,
  openMobileNavSubNavAnimation,
} from '../Navigation.animations';
import useNavState from '../Navigation.state';
import { RESET_ACTIVE_DELAY } from '../Navigation.types';
import NavItem from '../NavItem/NavItem';
import styles from './NavGroup.module.css';
import { NavGroupProps } from './NavGroup.types';

const NavGroup = (
  {
    navGroup,
    id,
    className,
    isActive,
    isReducedNav,
    onMouseOver,
    onMouseOut,
    onMouseOverMainNavItem,
    onMouseOutMainNavItem,
  }: NavGroupProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const animation = useRef<GSAPTimeline>();
  const $triggerEl = useRef<HTMLDivElement>(null);
  const $subNavWrapper = useRef<HTMLUListElement>(null);
  const $subNavLinks = useRef<HTMLLIElement[]>([]);

  const pathname = usePathname();
  const breakpoint = useUIStore((state) => state.breakpoint);

  const [setSubNavActiveStateDelay, clearSubNavActiveStateDelay] =
    useDelayedAction(RESET_ACTIVE_DELAY);
  const [
    activeSubNavItem,
    setActiveSubNavItem,
    setActiveMainNavItem,
    currentOpenedSubNav,
    setCurrentOpenedSubNav,
    currentGroup,
    mobileNavOpen,
    isMobileNav,
    currentPageMainNavId,
    currentPageSubNavId,
  ] = useNavState(
    (state) => [
      state.activeSubNavItem,
      state.setActiveSubNavItem,
      state.setActiveMainNavItem,
      state.currentOpenedSubNav,
      state.setCurrentOpenedSubNav,
      state.currentGroup,
      state.mobileNavOpen,
      state.isMobileNav,
      state.currentPageMainNavId,
      state.currentPageSubNavId,
    ],
    shallow,
  );

  const isGroupPage = currentGroup == id;

  const isOpened = currentOpenedSubNav === id && !isReducedNav;

  useEffect(() => {
    // clear animation on breakpoint change
    resetAnimation();
  }, [breakpoint]);

  function resetAnimation() {
    if (animation.current) {
      killTimeline(animation.current);
    }
  }

  useEffect(() => {
    // clear time of resetting link active state highlight
    clearSubNavActiveStateDelay();
  }, [pathname, clearSubNavActiveStateDelay]);

  useEffect(() => {
    // on desktop subnav, we show the current group subnav expanded by default
    if (isGroupPage && !isMobileNav && !isReducedNav) {
      setCurrentOpenedSubNav(id);
      // if we are on a subnav page, set it as active
      if (currentPageSubNavId) setActiveSubNavItem(currentPageSubNavId);
    }
  }, [
    isGroupPage,
    isMobileNav,
    isReducedNav,
    setCurrentOpenedSubNav,
    id,
    currentPageSubNavId,
    setActiveSubNavItem,
  ]);

  // only mobile/tablet
  const mobileAnimateIn = useCallback(() => {
    animation.current = openMobileNavSubNavAnimation({
      $subNavWrapper,
      $subNavLinks,
    });
    animation.current?.play();
  }, []);

  const mobileAnimateOut = useCallback(() => {
    animation.current = closeMobileNavSubNavAnimation({
      $subNavWrapper,
      $subNavLinks,
    });
    animation.current?.play();
    $triggerEl.current?.blur();
  }, []);

  useEffect(() => {
    if (isMobileNav) {
      // on mobile, only show one subnav at once, based on id
      if (mobileNavOpen) {
        if (currentOpenedSubNav === id) {
          mobileAnimateIn();
        } else {
          mobileAnimateOut();
        }
      } else {
        setCurrentOpenedSubNav(null);
        resetAnimation();
      }
    } else {
      resetAnimation();
    }
  }, [
    mobileNavOpen,
    currentOpenedSubNav,
    id,
    mobileAnimateIn,
    mobileAnimateOut,
    isMobileNav,
    setCurrentOpenedSubNav,
  ]);

  const onClickGroup = useCallback(() => {
    if (currentOpenedSubNav === id) {
      // if clicking a subnav that's already open, toggle it closed
      if (isMobileNav) {
        setCurrentOpenedSubNav(null);
        setActiveMainNavItem(currentPageMainNavId);
      }
    } else {
      setCurrentOpenedSubNav(id);
      setActiveMainNavItem(id);
    }
  }, [
    id,
    setCurrentOpenedSubNav,
    setActiveMainNavItem,
    currentOpenedSubNav,
    isMobileNav,
    currentPageMainNavId,
  ]);

  const onWrapperMouseOver = () => {
    onMouseOver(id);
  };

  const onWrapperMouseOut = () => {
    onMouseOut();
  };

  const onMouseOverSubNavItem = (subNavId: string | undefined) => {
    if (breakpoint && !isBreakpointOrGreater(breakpoint, 'lg')) return;
    clearSubNavActiveStateDelay();
    if (subNavId) setActiveSubNavItem(subNavId);
    if (id) setActiveMainNavItem(id);
  };

  const onMouseOutSubNavItem = () => {
    if (breakpoint && !isBreakpointOrGreater(breakpoint, 'lg')) return;
    setSubNavActiveStateDelay(() => {
      // when our mouse leave a subnav item
      // set the current subnav item as active, if there is one
      setActiveSubNavItem(currentPageSubNavId || null);
    });
  };

  return (
    <div
      className={cn(styles.navGroup, className)}
      aria-label={`${navGroup.groupLink?.label} navigation group`}
    >
      <div
        className={styles.navGroupItem}
        ref={$triggerEl}
        onMouseOver={onWrapperMouseOver}
        onMouseOut={onWrapperMouseOut}
        onFocus={onWrapperMouseOver}
        onBlur={onWrapperMouseOut}
      >
        {/* Nav group header */}
        <NavItem
          id={id}
          link={navGroup.groupLink}
          label={navGroup.label}
          icon={<SvgCaret className={styles.navGroupCaret} />}
          isGroupHeader={true}
          onMouseOver={onMouseOverMainNavItem}
          onMouseOut={onMouseOutMainNavItem}
          onFocus={onMouseOverMainNavItem}
          onBlur={onMouseOutMainNavItem}
          isActive={isActive}
          onClick={onClickGroup}
        />

        {/* Sub nav bar */}
        <div
          ref={ref}
          className={cn(styles.navGroupSubnav, isOpened && styles.active)}
        >
          <ul className={styles.navGroupSubnavItems} ref={$subNavWrapper}>
            {navGroup.group?.map((navItem: CMSLinkProps, index) => {
              return (
                <li
                  className={styles.subNavItemWrapper}
                  key={`${navItem._key}`}
                  ref={(element) =>
                    addToRefArray({ element, refArray: $subNavLinks, index })
                  }
                >
                  <NavItem
                    id={`${navItem._key}`}
                    link={navItem}
                    className={styles.subNavItem}
                    isGroupPage={isGroupPage}
                    hidden={!isOpened}
                    isActive={activeSubNavItem === navItem._key}
                    onMouseOver={() => onMouseOverSubNavItem(navItem._key)}
                    onMouseOut={onMouseOutSubNavItem}
                    onFocus={() => onMouseOverSubNavItem(navItem._key)}
                    onBlur={onMouseOutSubNavItem}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default forwardRef<HTMLDivElement, NavGroupProps>(NavGroup);
