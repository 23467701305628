import { gsap } from 'gsap';
import { RefObject, useEffect } from 'react';

import useUIStore from '~/state/ui';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import { tickerAdd, tickerRemove } from '~/utils/ticker';

import { NavigationState } from '../Navigation.types';

const useNavScroll = (
  $el: RefObject<HTMLDivElement>,
  state: NavigationState,
  {
    isPastSubNav,
  }: {
    isPastSubNav: () => boolean;
  },
) => {
  const breakpoint = useUIStore((state) => state.breakpoint);

  const setIsDesktopNavigationShowing = useUIStore(
    (state) => state.setIsDesktopNavigationShowing,
  );

  //   listen for scroll change
  useEffect(() => {
    let prevNavOffset = 0;
    let isFirstTick = true;
    let scrollDownChangeY = 0;
    let prevScrollDir = 0;
    let prevScrollY = 0;
    let animationDir = 0;

    const offsetValueUpdate = () => {
      if (typeof window === 'undefined' || !breakpoint || !$el.current) {
        return null;
      }

      const scrollY = ScrollPosition.y || 0;
      const scrollDir = ScrollPosition.direction || 0;

      if (scrollDir !== prevScrollDir) {
        prevScrollDir = scrollDir;
        prevScrollY = scrollY;
      }

      // start logging scroll after passing first trigger point
      const offsetScroll = Math.max(0, scrollY - state.banner.triggerStart);
      if (scrollDir == -1) {
        // on scroll up the nav will reappear, without banner, until the top is reached again
        const offset = offsetScroll > 0 ? -state.banner.height : 0;
        animationDir = 1;
        setNavOffset(offset);
      } else {
        // if scrolling past where main nav is no longer visible

        if (isPastSubNav()) {
          scrollDownChangeY = scrollY - prevScrollY;

          // if nav has reappeared (from scroll up), make it disappear again only after user has scrolled down a certain amount
          if (scrollDownChangeY > state.maxScrollUpDiff) {
            animationDir = -1;
            setNavOffset(
              -(
                state.banner.height +
                state.mainNav.height +
                state.subNav.height
              ),
              20,
            );
          }
        } else {
          // max offset after passing the banner
          const offsetBanner =
            -1 * gsap.utils.clamp(0, state.banner.height, offsetScroll);

          let offset = offsetBanner;

          // how many pixels past the top nav trigger end
          const pixelsPastBanner = scrollY - state.banner.triggerEnd;
          const pixelsPastBannerTriggerEnd = Math.min(
            state.mainNav.height,
            Math.max(0, pixelsPastBanner - state.mainNav.distanceToBanner),
          );

          offset = offset - pixelsPastBannerTriggerEnd;

          const pixelsPastMainNavTriggerEnd =
            scrollY - state.mainNav.triggerEnd;

          // how many pixels past the sub nav trigger start, cap at max subnav offset (subnav height)
          const pixelsPastSubNavTriggerEnd = Math.min(
            state.subNav.height,
            Math.max(
              0,
              pixelsPastMainNavTriggerEnd - state.subNav.distanceToMainNav,
            ),
          );

          offset = offset - pixelsPastSubNavTriggerEnd;

          animationDir = Math.abs(offset) > 0 ? -1 : 0;

          setNavOffset(offset);
        }
      }

      if (state.navOffset !== prevNavOffset || isFirstTick) {
        // since there is a change in nav offset, permit rendering this frame
        isFirstTick = false;
        prevNavOffset = state.navOffset;
        return true;
      }

      return false;
    };

    const setNavOffset = (offset: number, ease = 10) => {
      const scrollDir = ScrollPosition.direction || 0;
      state.navOffset =
        scrollDir === -1
          ? Math.min(offset, (state.navOffset += ease))
          : Math.max(offset, (state.navOffset -= ease));
    };

    const render = () => {
      if ($el.current) {
        if ($el.current) {
          if (animationDir === 1) {
            setIsDesktopNavigationShowing(true);
          } else if (animationDir === -1) {
            setIsDesktopNavigationShowing(false);
          }
          gsap.set($el.current, {
            y: state.navOffset,
          });
        }
      }
    };

    const tick = () => {
      if (offsetValueUpdate()) render();
    };

    tickerAdd(tick);

    return () => {
      tickerRemove(tick);
    };
  }, [setIsDesktopNavigationShowing, $el, state, breakpoint, isPastSubNav]);
};
export default useNavScroll;
