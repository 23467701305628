import { BaseButtonProps } from '~/components/atoms/Buttons/Ctas/ButtonBase.types';
import { CMSGraphicProps } from '~/components/atoms/Graphic/Graphic.types';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';
import { CMSAnnouncementBanner } from '~/components/molecules/AnnouncementBanner/AnnouncementBanner.types';
import { Breakpoint } from '~/types';

import { CMSNavGroupLink } from './NavGroup/NavGroup.types';

export const RESET_ACTIVE_DELAY = 100;
export const HIDE_DELAY = 250;

export type CMSModuleNavigationProps = {
  announcementBanner: CMSAnnouncementBanner;
  navigation: NavBarNavigationProps;
  hasReducedNav: boolean;
  isV4: boolean;
};

export type NavigationProps = {
  announcementBanner: CMSAnnouncementBanner;
  navigation: NavBarNavigationProps;
  isReducedNav: boolean;
  isV4: boolean;
};

export type AppLinks = {
  signupLink: CMSLinkProps;
  freeTrialLink: CMSLinkProps;
  reducedNavLink: CMSLinkProps;
};

export type NavigationNavChildProps =
  | CMSNavGroupLink
  | CMSLinkProps
  | BaseButtonProps;

export type CMSNavigationNavChildrenProps = NavigationNavChildProps[];

export type NavBarNavigationProps = {
  title: string;
  primaryLinks: CMSNavigationNavChildrenProps;
  logoLeft: {
    logo: CMSGraphicProps;
    link: CMSLinkProps;
  };
  mobileNavBottomLinks: NavigationNavChildProps[];
  v3AppLinks: AppLinks;
  v4AppLinks: AppLinks;
};

export type NavigationState = {
  breakpoint: Breakpoint | null;
  mainNav: {
    triggerStart: number;
    triggerEnd: number;
    height: number;
    distanceToBanner: number;
  };
  subNav: {
    triggerStart: number;
    height: number;
    distanceToMainNav: number;
  };
  banner: {
    triggerStart: number;
    triggerEnd: number;
    height: number;
  };
  // how many pixels scrolling back up to trigger nav to reappear
  maxScrollUpDiff: number;
  navOffset: number;
  hasBlur: boolean;
};
