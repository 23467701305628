'use client';
import Link from 'next/link';
import { useRef } from 'react';

import SvgFrameLogo from '~/assets/svg/logo-frame.svg';
import AdobeLogo from '~/components/atoms/AdobeLogo/AdobeLogo';
import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import AnnouncementBanner from '~/components/molecules/AnnouncementBanner/AnnouncementBanner';
import { dict } from '~/data/stores/Dictionary';
import useUIStore from '~/state/ui';
import { cn } from '~/utils';

import NavGroup from './NavGroup/NavGroup';
import styles from './Navigation.module.css';
import { NavigationNavChildProps, NavigationProps } from './Navigation.types';
import NavItem from './NavItem/NavItem';
import NavMobile from './NavMobile/NavMobile';
import useNavBar from './utils/useNavBar';

const NavigationClient = ({
  announcementBanner,
  navigation,
  isReducedNav,
  isV4,
}: NavigationProps) => {
  const $el = useRef<HTMLDivElement>(null);

  const $mainNavEl = useRef<HTMLDivElement>(null);
  const $subNavEl = useRef<HTMLDivElement>(null);
  const $bannerEl = useRef<HTMLDivElement>(null);

  const isNavigationOpaque = useUIStore((state) => state.isNavigationOpaque);

  const isNavigationDisabled = useUIStore(
    (state) => state.isNavigationDisabled,
  );
  const bannerDismissed = useUIStore((state) => state.bannerDismissed);

  const isNavigationBarVisible = useUIStore(
    (state) => state.isNavigationBarVisible,
  );
  const [
    { activeMainNavItem, currentOpenedSubNav, hasBlur },
    {
      dismissBanner,
      onMouseOverMainNavItem,
      onMouseOutMainNavItem,
      showDesktopSubNav,
      hideDesktopSubNav,
    },
  ] = useNavBar({
    navigation,
    refs: {
      $el,
      $mainNavEl,
      $subNavEl,
      $bannerEl,
    },
    isReducedNav,
  });

  if (isNavigationDisabled) return null;

  const renderNavChild = (button: NavigationNavChildProps) => {
    const { _type, _key } = button;
    const id = _key || '';

    switch (_type) {
      case 'link':
        return (
          <li key={id}>
            <NavItem
              id={id}
              className={styles.navChild}
              link={button}
              onMouseOver={onMouseOverMainNavItem}
              onMouseOut={onMouseOutMainNavItem}
              onFocus={onMouseOverMainNavItem}
              onBlur={onMouseOutMainNavItem}
              isActive={activeMainNavItem === id}
            />
          </li>
        );
      case 'button':
        const { to } = button;

        return (
          <li key={id}>
            <Button
              to={to}
              className={cn(styles.navigationButton, styles.navChild)}
              buttonVariant="pill"
              buttonColorScheme="solidLoud"
            >
              {to?.label}
            </Button>
          </li>
        );
      case 'navGroup':
        return (
          <li key={id}>
            <NavGroup
              className={styles.navChild}
              id={id}
              navGroup={button}
              ref={$subNavEl}
              isActive={activeMainNavItem === id}
              onMouseOver={showDesktopSubNav}
              onMouseOut={hideDesktopSubNav}
              onMouseOverMainNavItem={onMouseOverMainNavItem}
              onMouseOutMainNavItem={onMouseOutMainNavItem}
              isReducedNav={isReducedNav}
            />
          </li>
        );
      default:
        return null;
    }
  };

  const { v3AppLinks, v4AppLinks } = navigation;
  const appLinks = isV4 ? v4AppLinks : v3AppLinks;
  const { signupLink, reducedNavLink, freeTrialLink } = appLinks;

  return (
    <header
      ref={$el}
      className={cn(styles.navigation)}
      aria-label={navigation.title}
    >
      <div
        className={cn(
          styles.navigationWrapper,
          isReducedNav && styles.isReducedNav,
          // Dictate navbar visibility independently from the useNavScroll hook
          !isNavigationBarVisible && styles.isHidden,
        )}
      >
        {announcementBanner &&
          announcementBanner.content &&
          announcementBanner.content.length > 0 &&
          !bannerDismissed &&
          announcementBanner && (
            <AnnouncementBanner
              ref={$bannerEl}
              content={announcementBanner.content}
              onClose={dismissBanner}
              className={styles.navigationBannerEl}
            />
          )}
        <nav
          className={cn(
            styles.mainNavigation,
            currentOpenedSubNav !== null && !isReducedNav && styles.subNavOpen,
            isNavigationOpaque && styles.isNavigationOpaque,
          )}
          ref={$mainNavEl}
        >
          <div className={styles.navigationInner}>
            <div className={styles.left}>
              <Link
                href="/"
                className={styles.logoLeft}
                title={dict('navLinkHome')}
              >
                <SvgFrameLogo title="Frame.io Logo" className={styles.logo} />
              </Link>
            </div>

            <NavMobile
              isHidden={isReducedNav}
              signupLink={signupLink}
              freeTrialLink={freeTrialLink}
            >
              {navigation.primaryLinks.map(renderNavChild)}
            </NavMobile>

            <ul
              className={cn(
                styles.desktopNavigation,
                isReducedNav && styles.isHidden,
              )}
            >
              {navigation.primaryLinks.map(renderNavChild)}
            </ul>

            <ul className={styles.right}>
              {isReducedNav ? (
                <>
                  <li>
                    <NavItem
                      id="signup"
                      link={reducedNavLink}
                      onMouseOver={onMouseOverMainNavItem}
                      onMouseOut={onMouseOutMainNavItem}
                      onFocus={onMouseOverMainNavItem}
                      onBlur={onMouseOutMainNavItem}
                      isActive={activeMainNavItem === 'signup'}
                      isReducedNav={true}
                    />
                  </li>
                  <li>
                    <Button
                      to={signupLink}
                      className={styles.navigationButton}
                      buttonVariant="pill"
                      buttonColorScheme="solidLoud"
                    >
                      {signupLink?.label}
                    </Button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <NavItem
                      id="signup"
                      link={signupLink}
                      onMouseOver={onMouseOverMainNavItem}
                      onMouseOut={onMouseOutMainNavItem}
                      onFocus={onMouseOverMainNavItem}
                      onBlur={onMouseOutMainNavItem}
                      isActive={activeMainNavItem === 'signup'}
                    />
                  </li>
                  <li>
                    <Button
                      to={freeTrialLink}
                      className={styles.navigationButton}
                      buttonVariant="pill"
                      buttonColorScheme="solidLoud"
                    >
                      {freeTrialLink.label}
                    </Button>
                  </li>
                </>
              )}

              <li className={styles.adobeLogo}>
                <AdobeLogo />
              </li>
            </ul>
          </div>
          <figure className={cn(styles.backdrop, !hasBlur && styles.noBlur)} />
        </nav>
      </div>
    </header>
  );
};

export default NavigationClient;
